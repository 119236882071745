import * as React from "react"
import { graphql } from "gatsby"
import * as cheerio from 'cheerio'

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import JsonLd from "@components/common/JsonLd"
import Top from "@components/blog/Top"
import Main from "@components/blog/Main"
import Column from "@components/blog/Column"
import Service from "@components/blog/Service"

const Blog = ({ pageContext, data }) => {
  const latest_blogs = data.allMicrocmsBlog.edges.map(elem => ({...elem.node, id: elem.node.blogId})).filter(elem => elem.blogId !== pageContext.blogId).slice(0, 3);
  const $ = cheerio.load(pageContext.summary, { ignoreWhitespace: true });
  const description = pageContext.meta_description?
    pageContext.meta_description:
    $.text();
  return (
    <Layout>
      <Seo
        title={`${pageContext.title} | 既婚者向け総合メディア - Cuddle Place`}
        description={description}
        image={pageContext.hero_image.url}
        article={true}
      />
      <JsonLd
        type='blog'
        title={`${pageContext.title} | 既婚者向け総合メディア - Cuddle Place`}
        description={description}
        detail={pageContext}
      />
      <Top hero_image={pageContext.hero_image} />
      <Main {...pageContext}/>
      <Column related_blogs={pageContext.related_blogs.length > 0?
        pageContext.related_blogs:
        latest_blogs
      }/>
      <Service />
    </Layout>
  )
}

export const query = graphql`
  {
    allMicrocmsBlog(
      sort: { fields: [publishedAt], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          blogId
          title
          hero_image {
            url
          }
          summary
          meta_description
          content
          category
          publishedAt
          revisedAt
          author {
            name
            description
            image {
              url
            }
          }
        }
      }
    }
  }
`

export default Blog
